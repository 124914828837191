#userSettings {
  overflow: hidden;
  overflow-y: auto;

  width: 100%;
  background-color: rgba(22, 22, 22, 0.9);
  #banner {
    display: none;
    margin: 20px;
    .homeLink {
      color: white;
      text-decoration: none;
    }
    #authTitle {
      position: relative;
      font-size: 1.2em;
    }
  }
  h2 {
    text-align: center;
  }

  #contentCard {
    height: 100vh;
    top: 0;
    background-color: #424242;
    position: relative;

    overflow-y: auto;
    padding-bottom: 20px;
    #content {
      overflow-y: auto;
      margin-bottom: 80px;
      #topIcon {
        text-align: center;
        margin-top: 14%;
      }
      .userInput {
        width: 90%;
        margin: 3% 5%;
      }
      #subtitleText {
        text-align: center;
        opacity: 0.7;
      }
      #returnButton {
        position: absolute;
        bottom: 15px;
        left: 15px;
      }
      .dashboardCloseItemButton {
        width: 90vw;
        // width: 90%;
        // position: fixed;
        bottom: 3%;
        left: 5%;
      }
    }
  }
}
@media (orientation: landscape) {
  #userSettings {
    margin-bottom: 50px;
  }

  // #dashboardMain {
  //   #dashboardItems {
  //     grid-template-columns: auto auto auto auto;

  //     .dashboardItemButton {
  //       // margin: calc(0.5vh + 6px) calc(0.5vw + 8px);
  //       margin: 1vh 1vw;
  //       .dashboardItemContent {
  //         padding: 0 5px;
  //         overflow: hidden;
  //         height: 80px;

  //         padding-bottom: 80px;

  //         .dashboardItemIcon {
  //           margin-top: 5%;
  //         }
  //         .dashboardCloseItemButton {
  //           position: fixed;
  //         }
  //       }
  //       .dashboardItemAnimation {
  //         overflow-y: scroll;
  //         // left: 0;
  //         // top: 0;
  //         // position: fixed;
  //         // animation-name: fullscreen;
  //         // animation-duration: 0.5s;
  //         // z-index: 1000;
  //         // width: 100vw;
  //         height: 100vh;
  //       }
  //     }
  //   }
  // }
  @media screen and (min-width: 900px) {
    #userSettings {
      #contentCard {
        #content {
          .dashboardCloseItemButton {
            width: 50vw;
          }
        }
      }
    }
  }
  @media screen and (min-width: 1200px) {
    #userSettings {
      #contentCard {
        #content {
          .dashboardCloseItemButton {
            width: 50vw;
          }
        }
      }
    }
  }

  @media screen and (min-width: 1300px) {
    #userSettings {
      #contentCard {
        #content {
          .dashboardCloseItemButton {
            width: 40vw;
          }
        }
      }
    }
  }

  // @media screen and (min-width: 1900px) {
  //   #userSettings {
  //     #contentCard {
  //       #content {
  //         .dashboardCloseItemButton {
  //           width: 35vw;
  //         }
  //       }
  //     }
  //   }
  // }
}

@media screen and (min-width: 650px) {
  #userSettings {
    #contentCard {
      #content {
        width: 70vw;
        margin: auto;
        .dashboardCloseItemButton {
          width: 63vw;
        }
      }
    }
  }
}
@media screen and (min-width: 900px) {
  #userSettings {
    #contentCard {
      #content {
        width: 50vw;
        .dashboardCloseItemButton {
          width: 45vw;
        }
      }
    }
  }
}
@media screen and (min-width: 1600px) {
  #userSettings {
    #contentCard {
      #content {
        width:35vw;
        .dashboardCloseItemButton {
          width: 20vw;
          left:22%;
        }
      }
    }
  }
}