@import '../../../colors';

#dashboardMain {
  overflow: hidden;
  overflow-y: auto;

  width: 100vw;
  height: 100vh;
  background-color: rgba(22, 22, 22, 0.9);
  #banner {
    margin: 20px;
    position: relative;
    .homeLink {
      color: white;
      text-decoration: none;
    }
    #authTitle {
      position: relative;
      font-size: 1.2em;
    }
    #userTitle {
      position: absolute;
      // top: 10px;
      // right: 25px;
      top: -10px;
      right: 0;
      // font-size: 1.2em;
    }
  }
  h2 {
    text-align: center;
    // max-width: 80vw;
    // margin-left: 10vw;
  }
  #dashboardItems {
    display: grid;
    justify-content: space-evenly;
    grid-template-columns: auto auto;
    .dashboardItemButton {
      text-align: center;
      padding: 0;
      margin: calc(1vh + 6px) calc(1vw + 8px);

      .dashboardItemplsShown {
        cursor:default !important;
        display: block;

        width: 48vw;
        height: 150px;
      }
      @keyframes hideTheBlock {
        0% {
          width: 48vw;
          height: 150px;
          position: static;
        }
        100% {
          width: 48vw;
          height: 150px;
          position: static;
        }
      }
      .dashboardItemplshidden {
        position: static;
        width: 48vw;
        height: 150px;
        animation-name: hideTheBlock !important;
        animation-duration: 0.2s !important;
        position: absolute;
        z-index: -99;
      }

      .dashboardItemContent {
        overflow-y: auto;

        padding: 0;
        width: 48vw;
        height: 150px;
        .dashboardItemTitle {
          display: block;
          font-size: 1em;
          margin-top: 1em;
          margin-bottom: 1em;
          font-weight: bold;
        }
        .dashboardItemIcon {
          font-size: 3em;
        }
        .dashboardCloseItemButton {
          width: 90vw;
          // width: 90%;
          position: fixed;
          bottom: 3%;
          left: 5%;
        }
        &.dashboardItemAdmin{
          border: 1px $adminColor solid !important;
        }

      }
      @keyframes fullscreen {
        from {
          // left: inherit;
          width: 48vw;
          height: 150px;
          left: 25%;
          top: 20%;
        }
        to {
          // position: fixed;
          left: 0;
          top: 0;

          width: 100vw;
          height: 100vh;
        }
      }
      .dashboardItemAnimation {
        left: 0;
        top: 0;
        position: fixed;
        animation-name: fullscreen;
        animation-duration: 0.5s;
        z-index: 1000;
        width: 100vw;
        height: 100vh;
        .dashboardItemIcon {
          margin-top: 14%;
        }
      }
      @keyframes reverse {
        0% {
          // left: inherit;
          // width: 48vw;
          // height: 150px;
          // left: 25%;
          // top: 20%;
          position: fixed;
          left: 0;
          top: 0;

          width: 100vw;
          height: 50vh;
        }
        95% {
          position: fixed;
          width: 48vw;
          // height: 150px;
          // left: 25%;
          // top: 20%;
        }
        100% {
          position: fixed;
          // width: 48vw;
          // height: 150px;
          // left: 25%;
          // top: 20%;
        }
      }

      .dashboardItemAnimationReverse {
        // left: 0;
        // top: 0;
        z-index: 99;

        position: static;
        animation-name: reverse;
        animation-duration: 0.2s;
        // z-index:1000;
        // width: 100vw;
        // height: 100vh;
        // .dashboardItemIcon {
        //   margin-top:14%;
        // }
      }
      .dashboardItemAnimationReverseNoAni {
        // left: 0;
        // top: 0;
        position: static;
        // animation-name: reverse;
        // animation-duration: 0.2s;
        // z-index:1000;
        // width: 100vw;
        // height: 100vh;
        // .dashboardItemIcon {
        //   margin-top:14%;
        // }
      }
    }
  }
}

@media (orientation: landscape) {
  #dashboardMain {
    #dashboardItems {
      // grid-template-columns: auto auto auto auto;

      .dashboardItemButton {
        // margin: calc(0.5vh + 6px) calc(0.5vw + 8px);
        margin: 1vh 1vw;
        .dashboardItemContent {
          padding: 0 5px;
          overflow: hidden;
          height: 80px;

          padding-bottom: 80px;

          .dashboardItemIcon {
            margin-top: 5%;
          }
          .dashboardCloseItemButton {
            position: fixed;
          }
        }
        .dashboardItemAnimation {
          overflow-y: scroll;
          // left: 0;
          // top: 0;
          // position: fixed;
          // animation-name: fullscreen;
          // animation-duration: 0.5s;
          // z-index: 1000;
          // width: 100vw;
          height: 100vh;
        }
      }
    }
  }
  @media screen and (min-width: 900px) {
    #dashboardMain {
      #dashboardItems {
        width: 60vw;
        margin-left: 20vw;
        .dashboardItemButton {
          .dashboardItemContent {
            // width: 200px;
            height: 150px;
            .dashboardItemTitle {
              font-size: 1.3em;
            }
            .dashboardItemIcon {
              font-size: 4em;
            }
            .dashboardCloseItemButton {
              width: 50vw;
              left:25vw;
            }
          }
          .dashboardItemAnimation {
            overflow-y: scroll;
            // left: 0;
            // top: 0;
            // position: fixed;
            // animation-name: fullscreen;
            // animation-duration: 0.5s;
            // z-index: 1000;
            // width: 100vw;
            height: 100vh;
          }
        }
      }
    }
  }
  @media screen and (min-width: 1200px) {
    #dashboardMain {
      #dashboardItems {
        width: 600px;
        // margin-left: 10vw;
        margin: auto;
        .dashboardItemButton {
          .dashboardItemContent {
            // width: 200px;
            height: 150px;
            .dashboardItemTitle {
              font-size: 1.3em;
            }
            .dashboardItemIcon {
              font-size: 4em;
            }
            .dashboardCloseItemButton {
              width: 50vw;
              left:25vw;
            }
          }
          .dashboardItemAnimation {
            overflow-y: scroll;
            // left: 0;
            // top: 0;
            // position: fixed;
            // animation-name: fullscreen;
            // animation-duration: 0.5s;
            // z-index: 1000;
            // width: 100vw;
            height: 100vh;
          }
        }
      }
    }
  }
  // @media screen and (min-width: 900px) {
  //   #dashboardMain {
  //     #dashboardItems {
  //       .dashboardItemButton {
  //         .dashboardItemContent {

  //       }
  //       }
  //     }
  //   }
  // }
  @media screen and (min-width: 1300px) {
    #dashboardMain {
      #dashboardItems {
        .dashboardItemButton {
          .dashboardItemContent {
          .dashboardCloseItemButton {
            width: 40vw;
            left:30vw;
          }
        }
        }
      }
    }
  }
  @media screen and (min-width: 1900px) {
    #dashboardMain {
      #dashboardItems {
        .dashboardItemButton {
          .dashboardItemContent {
          .dashboardCloseItemButton {
            width: 35vw;
            left:32.5vw;
          }
        }
        }
      }
    }
  }
}
@media (orientation: portrait) {
  @media screen and (min-width: 650px) {
    #dashboardMain {
      #dashboardItems {
        .dashboardItemButton {
          .dashboardItemContent {
            height: 200px;
            .dashboardItemTitle {
              font-size: 1.5em;
            }
            .dashboardItemIcon {
              font-size: 4em;
              margin-top: 10%;
            }
          }
          .dashboardItemAnimation {
            overflow-y: scroll;
            // left: 0;
            // top: 0;
            // position: fixed;
            // animation-name: fullscreen;
            // animation-duration: 0.5s;
            // z-index: 1000;
            // width: 100vw;
            height: 100vh;
          }
        }
      }
    }
  }
  @media screen and (min-width: 900px) {
    #dashboardMain {
      #dashboardItems {
        width: 60vw;
        margin-left: 20vw;
        .dashboardItemButton {
          .dashboardItemContent {
            height: 220px;
            .dashboardItemTitle {
              font-size: 1.3em;
            }
            .dashboardItemIcon {
              font-size: 4em;
            }
            .dashboardCloseItemButton {
              width: 70vw;
              left: 15vw;
            }
          }
          .dashboardItemAnimation {
            overflow-y: scroll;
            // left: 0;
            // top: 0;
            // position: fixed;
            // animation-name: fullscreen;
            // animation-duration: 0.5s;
            // z-index: 1000;
            // width: 100vw;
            height: 100vh;
          }
        }
      }
    }
  }
  // @media screen and (min-width: 1000px) {
  //   #dashboardMain {
  //     #dashboardItems {
  //       width: 50vw;
  //       margin-left: 25vw;
  //       .dashboardItemButton {
  //         .dashboardItemContent {
  //           height: 200px;
  //           .dashboardItemTitle {
  //             font-size: 1.3em;
  //           }
  //           .dashboardItemIcon {
  //             font-size: 4em;
  //           }
  //         }
  //       }
  //     }
  //   }
  // }
  @media screen and (min-width: 1200px) {
    #dashboardMain {
      #dashboardItems {
        width: 50vw;
        margin-left: 25vw;
        .dashboardItemButton {
          .dashboardItemContent {
            height: 220px;
            .dashboardItemTitle {
              font-size: 1.3em;
            }
            .dashboardItemIcon {
              font-size: 4em;
            }
          }
        }
      }
    }
  }
  @media screen and (min-width: 1300px) {
    #dashboardMain {
      #dashboardItems {
        width: 40vw;
        margin-left: 30vw;
        .dashboardItemButton {
          .dashboardItemContent {
            .dashboardCloseItemButton {
              width: 40vw;
              left: 30vw;
            }
          }
        }
      }
    }
  }
  @media screen and (min-width: 1900px) {
    #dashboardMain {
      #dashboardItems {
        width: 35vw;
        margin-left: 32.5vw;
        .dashboardItemButton {
          .dashboardItemContent {
            .dashboardCloseItemButton {
              width: 35vw;
              left: 32.5vw;
            }
          }
        }
      }
    }
  }
}
