$authBg: #141414;

#loginContainer {
  margin: 0;
  padding: 0;
  background-color: $authBg;
  max-width: 100vw;
  .section {
    overflow: hidden;
    height: auto;
    min-height: 100vh;
    max-height: none;
  }

  #banner {
    margin: 20px;
    .homeLink {
      color: white;
      text-decoration: none;
    }
    #authTitle {
      position: relative;
      font-size: 1.2em;
    }
    .regButtonban {
      position: absolute;
      right: 12px;
      top: 12px;
      text-align: right;
      max-width: 50vw;
    }
  }
  #loginScreen {
    margin: 0;
    overflow: hidden;
    .title {
      margin-top: 6vh;
      text-align: center;
    }
    #loginForm {
      margin-top: 10vh;
      .loginInput {
        width: 78vw;
        margin: 10px 10vw;
      }
      .termPrivAccept {
        text-align: center;
        max-width: 60vw;
        margin: 0;
        margin-left: 20vw;
        font-size: 0.65em;
        color: rgb(105, 105, 105);
      }
      .loginSubmit {
        width: 40vw;
        $general-buttons: #007ac1;
        background-color: $general-buttons;
        margin: 10px 30vw;
      }
      .regButton {
        margin-top: 20vh;
        width: 40vw;
        margin: 10px 30vw;
      }
    }
  }
}
.altLogin {
  margin-top: 10vh;
  text-align: center;
  .altLoginText {
    font-size: 0.83em;
    color: rgb(105, 105, 105);
  }
  .altLoginButton {
    padding-left: 15%;
    color: white;
    width: 60vw;
    margin-bottom: 2vh;
    p {
      font-size: 0.9em;
    }
    .altLogo {
      position: absolute;
      left: 10%;
      //   width: 8vw;
      width: 35px;
      // padding:5px;
      padding-right: 20px;
      margin-left: -5px;
    }
    &:disabled {
      color: rgba(255, 255, 255, 0.3);
      background-color: gray;
      .altLogo {
        filter: gray; /* IE6-9 */
        -webkit-filter: grayscale(1); /* Google Chrome, Safari 6+ & Opera 15+ */
        filter: grayscale(1); /* Microsoft Edge and Firefox 35+ */
      }
    }
  }
}
.errorText {
  text-align: center;
  color: red;
}

.listtest {
  position: relative;
  left: 20%;
  text-align: center;
  width: 60%;
  .validatorListText {
    text-align: center;
    font-size: 0.7em;
    color: red;
  }
}

.checkmarkHidden {
  display: none;
}
.checkmark {
  text-align: center;
  position: absolute;
  top: 10%;
  width: 200px;
  margin: 0 auto;
  padding-top: 40px;
  left: 50%;
  margin-left: -100px;

  .path {
    stroke-dasharray: 1000;
    stroke-dashoffset: 0;
    animation: dash 2s ease-in-out;
    -webkit-animation: dash 2s ease-in-out;
  }

  .spin {
    animation: spin 1s;
    -webkit-animation: spin 1s;
    transform-origin: 50% 50%;
    -webkit-transform-origin: 50% 50%;
  }

  //   p {
  //     font-family: sans-serif;
  //     color: pink;
  //     font-size: 30px;
  //     font-weight: bold;
  //     margin: 20px auto;
  //     text-align: center;
  //     animation: text .5s linear .4s;
  //     -webkit-animation: text .4s linear .3s;
  //   }

  @-webkit-keyframes dash {
    0% {
      stroke-dashoffset: 1000;
    }
    100% {
      stroke-dashoffset: 0;
    }
  }

  @keyframes dash {
    0% {
      stroke-dashoffset: 1000;
    }
    100% {
      stroke-dashoffset: 0;
    }
  }

  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @-webkit-keyframes text {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes text {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}

@media (orientation: landscape) {
  .altLogin {
    margin-bottom: 5vw;
    .altLoginButton {
      padding-left: 5%;
      height: 100px;
      width: 30vw;
      margin: 0 10px;
    }
  }
}

@media (min-width: 700px) {
  .altLogin {
    .altLoginButton {
      height: 120px;
    }
  }
}
@media (min-width: 1200px) {
  .altLogin {
    .altLoginButton {
      p {
        font-size: 1.2em;
      }
      .altLogo {
        width: 55px;
        // padding:5px;
        padding-right: 30px;
        margin-left: -20px;
      }
    }
  }
}

.success-shown {
  opacity: 1;
  transition: all 250ms linear;
}

.success-hidden {
  height: 0;
  opacity: 0;
  // transition: all 250ms linear;
}
@media (min-width: 1500px) {
  #loginContainer {
    #loginScreen {
      .title {
        font-size: 2.5em;
      }
      #loginForm {
        .loginInput {
          width: 40vw;
          margin: 10px 30vw;
        }
        .loginSubmit {
          width: 20vw;
          $general-buttons: #007ac1;
          background-color: $general-buttons;
          margin: 10px 40vw;
        }
        .regButton {
          width: 20vw;
          margin: 10px 40vw;
        }
      }
    }
  }
  .altLogin {
    .altLoginButton {
      width: 20vw;
      height: 100px;
      p {
        font-size: 1em;
      }
      .altLogo {
        width: 55px;
        margin-left: 0;
      }
    }
  }
}
