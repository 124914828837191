:root() {
  position: relative;
}
.stepper {
  overflow: hidden;
  margin: 0 20px 20px 20px;
}
.stepperSection {
  // padding:10px;
  max-width: 100vw;
  height: 100vh;
}
.fields {
  margin: 5vw 20px;
  width: 90vw;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
  text-align: left;
  .stepperText {
    text-align: center;
    width: 100%;
    margin: 0;
  }
  .field {
    width: 40vw;
  }
  #stepperTitle {
    text-align: center;
  }

  .large-field {
    width: 100vw;
  }
  .connectButtons {
    width: 70vw;
    margin-left: 12.5vw;
    padding-left: 12.5%;
    text-align: left !important;
    // p{
    //   text-align: left !important;
    // }
    &#discordConnectButton {
      background-color: #7289da;
    }
    &#instagramConnectButton {
      background: #d6249f;
      background: radial-gradient(
        circle at 00% 107%,
        #cac47e 0%,
        #fd5949 45%,
        #d6249f 75%
      );
    }
    img {
      position: absolute;
      left: 10%;
      // margin-right: 20px;
      // margin-left: -20px;
    }
  }
}

// .controls {
//   position: fixed;
//   bottom: 20px;
//   width: 90vw;
//   display: flex;
//   justify-content: space-between;
// }
.controls {
  position: relative;
  // top: 20px;
  margin-left: 5vw;
  width: 90vw;
  display: flex;
  justify-content: space-between;
}

@media (min-width: 750px) {
  .fields {

  .connectButtons {
    width: 50vw;
    margin-left: 22.5vw;
    padding-left: 12%;
    img {
      left: 12%;
    }
    }
  }
}
@media (min-width: 1200px) {
  .stepperSection {
    // padding:10px;
    max-width: 50vw;
    width: 45vw;
    margin: 0 27.5vw;
    padding: 2vw 0;
  }
  .fields {
    margin: 50px 0;
    // width:50vw;
    width: 45vw;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;
    text-align: left;
    .stepperText {
      text-align: center;
      width: 100%;
      margin: 10px;
    }
    .field {
      width: 20vw;
    }
    .large-field {
      width: 45vw;
    }
    .connectButtons {
      width: 20vw;
      margin-left: 12.5vw;
      padding-left: 7%;
      text-align: left !important;
      // p{
      //   text-align: left !important;
      // }
      &#discordConnectButton {
        background-color: #7289da;
      }
      &#instagramConnectButton {
        background: #d6249f;
        background: radial-gradient(
          circle at 00% 107%,
          #cac47e 0%,
          #fd5949 45%,
          #d6249f 75%
        );
      }
      img {
        position: absolute;
        left: 7%;
        // margin-right: 20px;
        // margin-left: -20px;
      }
    }
  }
  .controls {
    position: relative;
    // top: 20px;
    width: 41vw;
    margin:2vw;
    display: flex;
    justify-content: space-between;
  }
}
