$authBg: #141414;

#verificationContainer {
  margin: 0;
  padding: 0;
  background-color: $authBg;
  max-width: 100vw;
  text-align: center;
  .section {
    overflow: hidden;
    height: auto;
    min-height: 100vh;
    max-height: none;
  }
  
  #banner {
    margin: 20px;
    margin-top:15vh;
    .homeLink {
      color: white;
      text-decoration: none;
    }
    #verificationTitle {
      font-family: Roboto, "Helvetica Neue", sans-serif ;
      position: relative;
      font-size: 2.5em;
    }
    .regButtonban {
      position: absolute;
      right: 12px;
      top: 12px;
      text-align: right;
      max-width: 50vw;
    }
  }
  
}