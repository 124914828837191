#betaInterface {
  overflow: hidden;
  text-align: center;
  width: 100%;
  background-color: rgba(22, 22, 22, 0.9);
  height:100vh;
  #betaUsersTitle{
    // padding:0;
    height:5vh;
    // max-width: 800px;
    // margin: auto;
  }
  #betaUserList{
    max-width: 800px;
    margin: auto;
    overflow-y: auto;
    height:85vh;
  }
  #addBetaUser{
    max-width: 800px;
    margin: auto;
    height:10vh;
    .betaUserButton{
      margin:15px;
    }
  }
}
  @media screen and (min-width: 900px) {
    #betaInterface{
      #betaUserList{
        // width: ;
      }
    }
  }
